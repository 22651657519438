import { TeamWithOrganizationDto } from '@portals/shared/admin/TeamDto';

import { request } from './request';

export async function fetchTeam(id: string): Promise<TeamWithOrganizationDto> {
  const response = await request<TeamWithOrganizationDto>({
    url: `teams/${id}`,
  });
  if (response.data) {
    return response.data;
  }
  throw new Error('Could not find team');
}

export async function fetchTeams(): Promise<TeamWithOrganizationDto[]> {
  const response = await request<TeamWithOrganizationDto[]>({
    url: 'teams',
  });
  return response.data;
}

export async function deleteTeam(id: string): Promise<void> {
  await request({
    method: 'DELETE',
    url: `teams/${id}`,
  });
}
