
























































































import {
  FeatureFlagInputDto,
  FeatureFlags,
} from '@portals/shared/admin/FeatureFlagDto';
import Vue from 'vue';

import AdminButtonGroup from '@/components/AdminButtonGroup.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DnbOrganizationSelect from '@/components/DnbOrganizationSelect.vue';
import DnbSelect from '@/components/DnbSelect.vue';
import DnbTeamSelect from '@/components/DnbTeamSelect.vue';
import DnbToggle from '@/components/DnbToggle.vue';
import DnbWarning from '@/components/DnbWarning.vue';
import Loader from '@/components/Loader.vue';
import { fetchFeatureFlag } from '@/service/featureFlagService';
import { request } from '@/service/request';
import { fetchUsers } from '@/service/userService';
import { scrollToError } from '@/utils';

import { Breadcrumb } from '../../models/Breadcrumb';

type Data = {
  loading: boolean;
  create: boolean;
  flag: FeatureFlagInputDto;
  userOptions: { value: string; label: string }[];
  typeOptions: { value: string; label: string }[];
  updating: boolean;
  status: Record<string, unknown> | null;
  errors: Record<string, unknown>;
  errorMessage: string | undefined;
};

const DELETE_CONFIRM_MESSAGE =
  'Are you sure you want to delete this? This action is ' +
  'not reversible and will revoke access for all clients.';

const emptyFlag: FeatureFlagInputDto = {
  key: FeatureFlags.ENABLE_READ_ONLY_MODE,
  value: false,
  type: 'everyone',
  typeId: null,
};

export default Vue.extend({
  name: 'edit-feature-flag',
  components: {
    DnbSelect,
    DnbToggle,
    DnbWarning,
    Loader,
    AdminButtonGroup,
    DnbOrganizationSelect,
    DnbTeamSelect,
    Breadcrumbs,
  },
  props: {},
  data(): Data {
    return {
      loading: false,
      create: !this.$route.params.id,
      flag: { ...emptyFlag },
      typeOptions: [
        { value: 'everyone', label: 'Everyone' },
        { value: 'email', label: 'User' },
        { value: 'team', label: 'Team' },
        { value: 'organization', label: 'Organization' },
      ],
      userOptions: [{ value: 'everyone', label: 'Everyone' }],
      updating: false,
      status: null,
      errors: {},
      errorMessage: undefined,
    };
  },
  computed: {
    featureFlagOptions(): { value: string; label: string }[] {
      return Object.keys(FeatureFlags).map((flag) => ({
        value: flag,
        label: flag,
      }));
    },
    breadcrumbs(): Breadcrumb[] {
      return [
        {
          name: 'Feature Flags',
          path: '/feature-flags',
        },
        this.create
          ? {
              name: 'Create Feature Flag',
            }
          : {
              name: this.flag.key,
            },
      ];
    },
  },
  async mounted() {
    this.loading = true;
    await this.loadUsers();
    if (this.$route.params.id) {
      this.flag = await fetchFeatureFlag(this.$route.params.id);
    }
    this.loading = false;
  },
  methods: {
    resetType() {
      this.flag.typeId = null;
    },
    async loadUsers() {
      const users = await fetchUsers();
      this.userOptions = users.map((user) => ({
        value: user.email,
        label: `${user.firstName} ${user.lastName} (${user.email})`,
      }));
    },
    async save() {
      this.updating = true;
      try {
        this.status = (
          await request<{ message: unknown }>({
            url: 'feature-flags',
            method: 'POST',
            data: this.flag,
          })
        ).data;
        this.$router.push('/feature-flags');
        this.updating = false;
      } catch (error) {
        this.updating = false;
        if (
          error &&
          error.response &&
          error.response.data.code === 'ValidationError'
        ) {
          this.errors = error.response.data.errors;
        } else if (error && error.response) {
          this.errorMessage = error.response.data.message;
        }
      }
    },
    async deleteItem() {
      try {
        if (confirm(DELETE_CONFIRM_MESSAGE)) {
          this.status = (
            await request<{ message: unknown }>({
              url: `feature-flags/${this.flag.id}`,
              method: 'DELETE',
            })
          ).data;
          this.$router.push('/feature-flags');
          this.updating = false;
        }
      } catch (error) {
        if (error && error.response) {
          this.errorMessage = error.response.data.message;
          Vue.nextTick(scrollToError);
        }
      }
    },
  },
});
