



















import Vue from 'vue';

import DnbSelect from '@/components/DnbSelect.vue';
import { fetchTeams } from '@/service/teamService';

type Option = { value: string | undefined; label: string };

type Data = {
  loading: boolean;
  options: Option[];
};

export default Vue.extend({
  name: 'dnb-teams-select',
  components: { DnbSelect },

  props: {
    value: {
      type: [String, Array as () => string[]],
      default: '',
    },
    id: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    validationError: { type: Array, default: undefined },
    multiple: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    showEveryoneOption: {
      type: Boolean,
      default: false,
    },
    help: {
      type: Boolean,
      default: false,
    },
  },
  data(): Data {
    return {
      options: [],
      loading: false,
    };
  },
  async mounted() {
    // @ts-ignore
    this.loading = true;
    const response = await fetchTeams();
    // @ts-ignore
    this.options = [
      ...response.map((team) => ({
        value: team.id,
        label: `${team.name} (${
          team.organization ? team.organization.name : 'no organization'
        })`,
      })),
    ];
    if (this.showEveryoneOption) {
      this.options.unshift({ value: 'everyone', label: 'Every team' });
    }
    // @ts-ignore
    this.loading = false;
  },
  methods: {
    onInput(value: string) {
      this.$emit('input', value);
    },
  },
});
