import { boolean, nativeEnum, object, string, z } from 'zod';

import { UUID_MATCHER } from '../common/matchers';
import { INVALID_UUID } from '../common/messages';

export type FeatureFlagsForCurrentAdmin = {
  ENABLE_CIAM: boolean;
  ENABLE_READ_ONLY_MODE: boolean;
  ENABLE_TEAMS: boolean;
  ENABLE_TEST_ENVIRONMENT_WARNING: boolean;
  ENABLE_STATUS_PAGE_CHARTS: boolean;
};

export type FeatureFlag =
  | 'ENABLE_CIAM'
  | 'ENABLE_READ_ONLY_MODE'
  | 'ENABLE_TEAMS'
  | 'ENABLE_TEST_ENVIRONMENT_WARNING'
  | 'ENABLE_STATUS_PAGE_CHARTS';

export type FeatureFlagType = 'organization' | 'team' | 'email' | 'everyone';

export enum FeatureFlags {
  ENABLE_CIAM = 'ENABLE_CIAM',
  ENABLE_READ_ONLY_MODE = 'ENABLE_READ_ONLY_MODE',
  ENABLE_TEAMS = 'ENABLE_TEAMS',
  ENABLE_TEST_ENVIRONMENT_WARNING = 'ENABLE_TEST_ENVIRONMENT_WARNING',
  ENABLE_STATUS_PAGE_CHARTS = 'ENABLE_STATUS_PAGE_CHARTS',
}

export interface FeatureFlagDto {
  id: string;
  key: FeatureFlags;
  value: boolean;
  type: FeatureFlagType;
  typeId: string | null;
  name?: string;
}

export const featureFlagInputSchema = object({
  id: string().regex(UUID_MATCHER, INVALID_UUID).optional(),
  key: nativeEnum(FeatureFlags),
  value: boolean(),
  type: z.enum(['organization', 'team', 'email', 'everyone']),
  typeId: string().nullable(),
});

export type FeatureFlagInputDto = z.infer<typeof featureFlagInputSchema>;
