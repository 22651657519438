



















import { OrganizationDto } from '@portals/shared/admin/OrganizationDto';
import Vue from 'vue';

import DnbSelect from '@/components/DnbSelect.vue';
import { fetchOrganizations } from '@/service/organizationService';

type Option = { value: string | undefined; label: string };

type Data = {
  loading: boolean;
  options: Option[];
};

export default Vue.extend({
  name: 'dnb-organization-select',
  components: { DnbSelect },

  props: {
    value: {
      type: [String, Array as () => string[]],
      default: '',
    },
    id: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    validationError: { type: Array, default: undefined },
    multiple: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    showEveryoneOption: {
      type: Boolean,
      default: false,
    },
    help: {
      type: Boolean,
      default: false,
    },
  },
  data(): Data {
    return {
      options: [],
      loading: false,
    };
  },
  async mounted() {
    // @ts-ignore
    this.loading = true;
    const response = await fetchOrganizations();
    // @ts-ignore
    this.options = [
      ...response.map((organization: OrganizationDto) => ({
        value: organization.id,
        label: organization.name,
      })),
    ];
    if (this.showEveryoneOption) {
      this.options.unshift({ value: 'everyone', label: 'Every organization' });
    }
    // @ts-ignore
    this.loading = false;
  },
  methods: {
    onInput(value: string) {
      this.$emit('input', value);
    },
  },
});
