


































import Vue from 'vue';

import HelpText from '@/components/HelpText.vue';

export default Vue.extend({
  name: 'dnb-toggle',
  components: { HelpText },
  props: {
    id: { type: String, required: true },
    value: {},
    offLabel: {
      type: String,
      default: 'False',
    },
    onLabel: {
      type: String,
      default: 'True',
    },
    label: {
      type: String,
      default: '',
    },
    offValue: {
      default: false,
    },
    onValue: {
      default: true,
    },
    help: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    selectedHelpText: string;
    displayHelpText: boolean;
  } {
    return {
      selectedHelpText: '',
      displayHelpText: false,
    };
  },
  methods: {
    setSelectedHelpText(text: string) {
      this.selectedHelpText = text;
      this.displayHelpText = !this.displayHelpText;
      if (!this.displayHelpText) {
        this.selectedHelpText = '';
      }
    },
    resetHelpText() {
      this.selectedHelpText = '';
      this.displayHelpText = false;
    },
  },
});
